import React from "react";
import Chip from "@material-ui/core/Chip";
import "./svgComponentsA.css";

const ManOnCycle = () => {
  return (
    <div className="containerSvg">
      <Chip size="small" label="SVG animation" clickable color="primary" />
      <Chip
        size="small"
        label="Adobe XD"
        clickable
        color="primary"
        style={{ marginLeft: 5 }}
      />
      <Chip
        size="small"
        label="CSS"
        clickable
        color="primary"
        style={{ marginLeft: 5 }}
      />
      <div className="mainDivSvg">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox="0 0 1022.753 784.976"
        >
          <defs>
            <linearGradient
              id="linear-gradient"
              x1="0.879"
              y1="0.215"
              x2="0.017"
              y2="0.799"
              gradientUnits="objectBoundingBox"
            >
              <stop offset="0" stopColor="#d0ccee" />
              <stop offset="1" stopColor="#736deb" />
            </linearGradient>
          </defs>
          <g id="manBike" transform="translate(-318.935 -83)">
            <g id="background" transform="translate(271.007 59.002)">
              <path
                id="Path_1"
                data-name="Path 1"
                d="M280.88,172.94c-5.48,6.65-7.59,16-9.95,24.79-19,70.84-65.82,127.82-119.14,168.52-22.58,17.24-50.07,40.36-45.13,71.27,2.66,16.6,14.33,28.82,23,42.34,18.19,28.31,24.09,66.79,15.42,100.68-9.86,38.56-37.42,74.54-28.89,113.53,5.26,24,23.12,41.08,40.29,55.76q40.18,34.33,82.92,64.32c19.1,13.4,39.42,26.44,61.68,28.14,19.6,1.5,38.8-5.95,57.41-13.3,16.26-6.43,32.93-13.12,46.06-26,8.57-8.41,15.4-19.26,24.92-26.14,22.36-16.16,51.08-5.92,76.72,1,60.5,16.24,125.25,10.31,182.82-16.74,88.31-41.5,155.62-128.65,242-175.41,37.14-20.11,77.54-32.59,111.93-58.49a209.088,209.088,0,0,0,49.2-53.24c26-40.11,40.06-91.25,36-141.31s-26.81-98.3-62.54-126.56c-38.25-30.25-86.56-35.9-130.08-54.06-44.94-18.76-84.92-51-129.43-71.13A264.67,264.67,0,0,0,577.82,86c-48.44,24.5-97.54,81.66-151.49,86C387.89,175,310.9,136.52,280.88,172.94Z"
                transform="translate(-58.15 -33.51)"
                opacity="0.74"
                fill="url(#linear-gradient)"
              />
            </g>
            <g id="rightWheel" transform="translate(271.007 59.002)">
              <g transform="translate(271.007 59.002)">
                <path
                  id="Path_37"
                  data-name="Path 37"
                  d="M752.57,774.9,746.31,677l-.13-.21-6.32,2.2L710.77,763.8,664.52,731l73.69-53.41.07-.21.42-.15,4.75-3.45-77.24-43.31-18.93,20.8,3,19.5,88.8,1.58v2l-90.48-1.62-3.4-22.11,25.89-28.45L709.81,577l.69,1.87,8.2-9,30.54,5.47v-1.21l2,.06v1.51l45.36,8.12-42.33,84.65-6.17,4.47V674l97.81-34.08L834.3,731.13l-83-52.87-2.92-1.64,48.94,78.89Zm-4-94.34,5.85,91.38,40.05-17.38ZM668,731l41.79,29.63,27.34-79.74Zm82.32-55.66,2.09,1.17,80.35,51.2,10.8-84.87Zm-7.1-7.2,2.51,4.05.44-.32,3-94.53L719.43,572l-8.16,9Zm-75.39-39.05,74.84,42-1.3-2.13-31.58-86.27-37.2,40.88ZM751.2,577.7l-3,92.69,4.52-3.28,40.91-81.82Z"
                  transform="translate(-71.15 -57.51)"
                  fill="#535461"
                />
                <path
                  id="Path_46"
                  data-name="Path 46"
                  d="M747.48,556.12a117.49,117.49,0,0,0-31.41,4.24,115.568,115.568,0,0,0-15.46,5.47,117.7,117.7,0,1,0,46.86-9.71Zm-.4,206.09a88.86,88.86,0,0,1-36.9-169.68,87.774,87.774,0,0,1,14.56-5.17,88.85,88.85,0,1,1,22.35,174.85Z"
                  transform="translate(-71.15 -57.51)"
                  fill="#535461"
                />
              </g>
            </g>
            <g id="leftWheel" transform="translate(271.007 59.002)">
              <g transform="translate(271.007 59.002)">
                <path
                  id="Path_38"
                  data-name="Path 38"
                  d="M326.38,774.5l-6.27-97.94-.13-.21-6.32,2.2L284.58,763.4l-46.25-32.79L312,677.2l.07-.21.42-.15,4.75-3.45L240,630.08l-18.92,20.8,3,19.5L312.9,672v2l-90.48-1.62L219,650.24l25.9-28.46,38.75-45.2.69,1.87,8.2-9L323,574.91V573.7l2,.06v1.51l45.36,8.12L328.07,668l-6.17,4.47v1.06l97.8-34.08-11.61,91.21-83-52.87-2.92-1.64,48.94,78.88Zm-4-94.34,5.85,91.38,40.05-17.38ZM241.8,730.59l41.79,29.63,27.34-79.74Zm82.32-55.66,2.09,1.17,80.35,51.2,10.8-84.87Zm-7.1-7.2,2.51,4.05.44-.32,3-94.53-29.74-5.32-8.16,9ZM241.6,628.67l74.84,42-1.3-2.13-31.58-86.27-37.19,40.87ZM325,577.28,322,670l4.52-3.28,40.91-81.82Z"
                  transform="translate(-71.15 -57.51)"
                  fill="#535461"
                />
                <path
                  id="Path_57"
                  data-name="Path 57"
                  d="M436.07,647.46a117.22,117.22,0,0,0-23.71-48.58,117.885,117.885,0,1,0,26.58,74.49,117.568,117.568,0,0,0-2.87-25.91ZM320.88,761.81A88.75,88.75,0,1,1,408.28,689a88.75,88.75,0,0,1-87.4,72.81Z"
                  transform="translate(-71.15 -57.51)"
                  fill="#535461"
                />
              </g>
            </g>
            <g id="bike">
              <rect
                id="Rectangle_1"
                data-name="Rectangle 1"
                width="60"
                height="19"
                transform="translate(615.3 571.16) rotate(44.62)"
                fill="#7d7d87"
              />
              <path
                id="Path_39"
                data-name="Path 39"
                d="M425.63,549.5S450.42,536.42,460,528a130.4,130.4,0,0,1,18.53-13.53l31.1-18.93,65-5,3,11s5,16-45,30-75,36-75,36S425.63,578.5,425.63,549.5Z"
                transform="translate(199.857 1.492)"
                fill="#db8b8b"
              />
              <path
                id="Path_40"
                data-name="Path 40"
                d="M460.28,565.37c-1.77,1.35-2.65,2.13-2.65,2.13s-32,11-32-18c0,0,1.55-.82,4-2.19a27.67,27.67,0,0,0,23.58,13.19h3.39Z"
                transform="translate(199.857 1.492)"
                opacity="0.1"
              />
              <path
                id="Path_41"
                data-name="Path 41"
                d="M576.69,507.06c-2.78,5.89-12.37,15.57-44.06,24.44-40.61,11.37-64.73,28-72.35,33.87-1.77,1.35-2.65,2.13-2.65,2.13s-32,11-32-18c0,0,1.55-.82,4-2.19,7.43-4.07,23.16-13,30.34-19.35a130,130,0,0,1,18.53-13.53l31.1-18.93,11.58-.89,53.42-4.11,3,11S578.3,503.65,576.69,507.06Z"
                transform="translate(199.857 1.492)"
                opacity="0.05"
              />
              <path
                id="Path_42"
                data-name="Path 42"
                d="M426.13,543l-26.5,17.5,77.6,65s11.4-7,5.4-15c0,0-20-17-21-41l-6-8h-3.39A27.671,27.671,0,0,1,426.129,543Z"
                transform="translate(199.857 1.492)"
                fill="#c4c6d2"
              />
              <path
                id="Path_43"
                data-name="Path 43"
                d="M576.69,507.06c-16-7.73-55.06-8.56-55.06-8.56l-.42-3.89,53.42-4.11,3,11S578.3,503.65,576.69,507.06Z"
                transform="translate(199.857 1.492)"
                opacity="0.1"
              />
              <path
                id="Path_44"
                data-name="Path 44"
                d="M521.63,496.5s47,1,59,11l-6-121h-65Z"
                transform="translate(199.857 1.492)"
                fill="#abb1d1"
              />
              <path
                id="Path_45"
                data-name="Path 45"
                d="M521.63,496s47,1,59,11l-6-121h-65Z"
                transform="translate(199.857 1.492)"
                opacity="0.05"
              />
              <path
                id="Path_47"
                data-name="Path 47"
                d="M559.39,422.46s117.65-4,120.05,11.21-117.65,8.8-117.65,8.8S552.19,432.86,559.39,422.46Z"
                transform="translate(199.857 1.492)"
                fill="#535461"
              />
              <path
                id="Path_48"
                data-name="Path 48"
                d="M559.39,422.46s117.65-4,120.05,11.21-117.65,8.8-117.65,8.8S552.19,432.86,559.39,422.46Z"
                transform="translate(199.857 1.492)"
                opacity="0.05"
              />
              <circle
                id="Ellipse_5"
                data-name="Ellipse 5"
                transform="translate(680.817 748.102)"
                fill="none"
                stroke="#c1272d"
                strokeMiterlimit="10"
                strokeWidth="1"
              />
              <rect
                id="Rectangle_3"
                data-name="Rectangle 3"
                width="20.81"
                height="180.88"
                transform="translate(643.197 484.782)"
                fill="#7d7d87"
              />
              <path
                id="Path_49"
                data-name="Path 49"
                d="M464.15,483.28v13.93c-6.92.9-14,1.47-20.81,1.69V483.28Z"
                transform="translate(199.857 1.492)"
                opacity="0.1"
              />
              <path
                id="Path_50"
                data-name="Path 50"
                d="M462.59,468.29a77.732,77.732,0,0,1-8.7-1.17c-11.28-2.16-39.72-6.69-47.37,1-9.6,9.6-6.4,26.41,19.21,28.81s69.63-4,78.44-13.61C504.17,483.28,488.24,470.26,462.59,468.29Z"
                transform="translate(199.857 1.492)"
                fill="#535461"
              />
              <path
                id="Path_51"
                data-name="Path 51"
                d="M387.4,475.4l218.5-68.03,7.2,16.81L389,501.01Z"
                transform="translate(271.007 59.002)"
                fill="#7d7d87"
              />
              <path
                id="Path_52"
                data-name="Path 52"
                d="M452.23,582.65,609.9,427.38l8.8,12.8L468.23,597.05Z"
                transform="translate(271.007 59.002)"
                fill="#7d7d87"
              />
              <path
                id="Path_53"
                data-name="Path 53"
                d="M724.73,587.36a87.775,87.775,0,0,0-14.56,5.17l-9.56-26.71a115.55,115.55,0,0,1,15.46-5.47Z"
                transform="translate(199.857 1.492)"
                opacity="0.1"
              />
              <path
                id="Path_54"
                data-name="Path 54"
                d="M680.33,608.26l-11.2,6.4L590.99,396.33l-3.5-9.77,18.41-10.41,4.61,14.38Z"
                transform="translate(271.007 59.002)"
                fill="#7d7d87"
              />
              <path
                id="Path_55"
                data-name="Path 55"
                d="M610.51,390.53l-19.52,5.8-3.5-9.77,18.41-10.41Z"
                transform="translate(271.007 59.002)"
                opacity="0.1"
              />
              <path
                id="Path_56"
                data-name="Path 56"
                d="M562.59,464.88l109.65-37.62s19.21,2.4,12,18.41L573.8,478.48S557,480.08,562.59,464.88Z"
                transform="translate(199.857 1.492)"
                fill="#535461"
              />
              <path
                id="Path_58"
                data-name="Path 58"
                d="M438.94,673.37a119.073,119.073,0,0,1-1.417,18.3C427.92,691,418,690,408.28,689a89.59,89.59,0,0,0-.79-35.88c9.47-2,19.14-3.91,28.58-5.64a117.568,117.568,0,0,1,2.87,25.89Z"
                transform="translate(199.857 1.492)"
                opacity="0.1"
              />
              <path
                id="Path_59"
                data-name="Path 59"
                d="M464.15,642.89v21.27H443.34v-18C450.54,644.91,457.54,643.8,464.15,642.89Z"
                transform="translate(199.857 1.492)"
                opacity="0.1"
              />
              <path
                id="Path_60"
                data-name="Path 60"
                d="M329.69,673s168.08-44,181.68-27.21S539.38,685,497,693s-170.48-13.61-170.48-13.61Z"
                transform="translate(199.857 1.492)"
                fill="#7d7d87"
              />
              <path
                id="Path_61"
                data-name="Path 61"
                d="M412.36,598.88,391,618.43a89,89,0,0,0-12.76-13.28l19.94-20.83a118.769,118.769,0,0,1,14.18,14.56Z"
                transform="translate(199.857 1.492)"
                opacity="0.1"
              />
              <path
                id="Path_62"
                data-name="Path 62"
                d="M252.94,606.66,378.59,475.4l4.01,30.41L259.34,618.66Z"
                transform="translate(271.007 59.002)"
                fill="#7d7d87"
              />
              <circle
                id="Ellipse_6"
                data-name="Ellipse 6"
                cx="12.81"
                cy="12.81"
                r="12.81"
                transform="translate(509.537 661.652)"
                opacity="0.1"
              />
              <circle
                id="Ellipse_7"
                data-name="Ellipse 7"
                cx="12.81"
                cy="12.81"
                r="12.81"
                transform="translate(507.927 661.652)"
                fill="#535461"
              />
              <circle
                id="Ellipse_8"
                data-name="Ellipse 8"
                cx="12.81"
                cy="12.81"
                r="12.81"
                transform="translate(933.327 660.452)"
                opacity="0.1"
              />
              <circle
                id="Ellipse_9"
                data-name="Ellipse 9"
                cx="12.81"
                cy="12.81"
                r="12.81"
                transform="translate(934.127 662.052)"
                fill="#535461"
              />
              <circle
                id="Ellipse_10"
                data-name="Ellipse 10"
                cx="25.61"
                cy="25.61"
                r="25.61"
                transform="translate(693.617 636.052)"
                opacity="0.1"
              />
              <circle
                id="Ellipse_11"
                data-name="Ellipse 11"
                cx="25.61"
                cy="25.61"
                r="25.61"
                transform="translate(695.227 636.052)"
                fill="#535461"
              />
              <path
                id="Path_63"
                data-name="Path 63"
                d="M636.63,304.5l-2.38,30.11a67.617,67.617,0,0,0,.15,11.45c.31,3.37,0,9.27-3.3,18.27a104.892,104.892,0,0,0-6.47,35.9V434.5l-23-4s1-48-3-56,3-79,3-79Z"
                transform="translate(199.857 1.492)"
                fill="#db8b8b"
              />
              <path
                id="Path_64"
                data-name="Path 64"
                d="M635.45,319.42l-1.2,15.19a67.537,67.537,0,0,0,.16,11.44c.31,3.37,0,9.27-3.3,18.28a104.89,104.89,0,0,0-6.47,35.9V434.5l-23-4s1-48-3-56c-2.83-5.67-.15-42.94,1.66-64.21.75-8.75,1.34-14.79,1.34-14.79l35,9Z"
                transform="translate(199.857 1.492)"
                opacity="0.05"
              />
              <path
                id="Path_65"
                data-name="Path 65"
                d="M522.65,534.5,514,583.7a112.206,112.206,0,0,1-5,19.3c-5.07,13.86-1.83,33.29.63,43.89,1.09,4.68,2,7.64,2,7.64l-26,14-8-6v-4l-1-89c-6-14-4-35,0-43a33.9,33.9,0,0,0,2.38-8.22c2-10.72,2.62-25.78,2.62-25.78s16-27,35-9c7,6.63,9.66,15.3,10.22,23.6C527.83,521.34,522.65,534.5,522.65,534.5Z"
                transform="translate(199.857 1.492)"
                fill="#db8b8b"
              />
              <path
                id="Path_66"
                data-name="Path 66"
                d="M526.85,507.1c-13.87,8.24-38,10.57-47.84,11.18,2-10.72,2.62-25.78,2.62-25.78s16-27,35-9C523.63,490.13,526.29,498.8,526.85,507.1Z"
                transform="translate(199.857 1.492)"
                opacity="0.1"
              />
              <path
                id="Path_67"
                data-name="Path 67"
                d="M560.63,482.5a182.994,182.994,0,0,0-30,20c-16,13-56,14-56,14v-15c1-18-16-38-39-94-14.69-35.76-10.62-56-5.76-66,2.76-5.65,5.76-8,5.76-8l125,24,4.47,13.4,9.53,28.6s-35,2-41,5-2,30-2,30C525.63,462.5,560.63,482.5,560.63,482.5Z"
                transform="translate(199.857 1.492)"
                fill="#abb1d1"
              />
              <path
                id="Path_68"
                data-name="Path 68"
                d="M636.63,304.5l-1.18,14.92c-17.4,5-29.82-10.92-29.82-10.92a45.8,45.8,0,0,0-5.34,1.79c.75-8.75,1.34-14.79,1.34-14.79Z"
                transform="translate(199.857 1.492)"
                opacity="0.1"
              />
              <path
                id="Path_69"
                data-name="Path 69"
                d="M565.1,370.9c-.47,4.67-.47,7.6-.47,7.6s-23,19-62-10-74-21-74-21,.41-2.32,1.24-6c2.76-5.65,5.76-8,5.76-8l125,24Z"
                transform="translate(199.857 1.492)"
                opacity="0.1"
              />
              <path
                id="Path_70"
                data-name="Path 70"
                d="M650.11,265.45c-2.61,20.35-3.48,46-3.48,46-23,18-41-5-41-5-21.72,5.83-31.93,24.84-36.74,41.43a110.761,110.761,0,0,0-4.26,27.57s-6.08,5-17.28,6c-10.59.89-25.77-1.87-44.72-16-39-29-74-21-74-21s7-40,23-52,28-27,48-73,61-35,61-35,2-9,39,0A60.27,60.27,0,0,1,624,197.36C643.79,214,653.39,239.81,650.11,265.45Z"
                transform="translate(199.857 1.492)"
                fill="#fff"
              />
              <path
                id="Path_71"
                data-name="Path 71"
                d="M613,207c-.05,1.33-.16,2.81-.34,4.46-2,18-4,16-26,20-13.68,2.49-20.79-8.94-24.21-17.94a56.628,56.628,0,0,1-2.79-10.06l48-8S613.41,193.85,613,207Z"
                transform="translate(199.857 1.492)"
                fill="#db8b8b"
              />
              <path
                id="Path_72"
                data-name="Path 72"
                d="M568.89,347.93a110.761,110.761,0,0,0-4.26,27.57s-6.08,5-17.28,6a9.318,9.318,0,0,1-.72-2c-2-9-17-25-17-25-10-9-12-25-12-25L501.13,281l-5.5-4.5s23-18,43-3,14,47,14,47l4,11C560.78,335,565,341.17,568.89,347.93Z"
                transform="translate(199.857 1.492)"
                opacity="0.05"
              />
              <path
                id="Path_73"
                data-name="Path 73"
                d="M498.63,275.5l22,53s2,16,12,25c0,0,15,16,17,25s30,33,30,33,26,24,28,33c0,0,24,7,21,21,0,0,18-18,0-35s-42-52-42-52-14-37-27-48l-4-11s6-32-14-47S498.63,275.5,498.63,275.5Z"
                transform="translate(199.857 1.492)"
                fill="#db8b8b"
              />
              <path
                id="Path_74"
                data-name="Path 74"
                d="M511.63,654.5l-26,14-8-6v-4a60.56,60.56,0,0,0,25.55-7.71c2.4-1.36,4.66-2.7,6.47-3.88C510.7,651.54,511.63,654.5,511.63,654.5Z"
                transform="translate(199.857 1.492)"
                opacity="0.1"
              />
              <path
                id="Path_75"
                data-name="Path 75"
                d="M541.63,233.5l14,55s-43,7-52,0-26-9-26-9,18-52,28-58S541.63,233.5,541.63,233.5Z"
                transform="translate(199.857 1.492)"
                opacity="0.1"
              />
              <path
                id="Path_76"
                data-name="Path 76"
                d="M541.63,231.5l14,55s-43,7-52,0-26-9-26-9,18-52,28-58S541.63,231.5,541.63,231.5Z"
                transform="translate(199.857 1.492)"
                fill="#fff"
              />
              <path
                id="Path_77"
                data-name="Path 77"
                d="M613,206a35,35,0,0,1-50.55,6.52,56.629,56.629,0,0,1-2.79-10.06l48-8S613.41,192.85,613,206Z"
                transform="translate(199.857 1.492)"
                opacity="0.1"
              />
              <path
                id="Path_78"
                data-name="Path 78"
                d="M619.63,184.5a35,35,0,0,1-70,0v-1.74a35.011,35.011,0,1,1,70,1.74Z"
                transform="translate(199.857 1.492)"
                fill="#db8b8b"
              />
              <path
                id="Path_79"
                data-name="Path 79"
                d="M619.63,184.5a34.89,34.89,0,0,1-1.32,9.54,13.47,13.47,0,0,1-8.08-.74c-3.74-1.47-6.95-4-10.54-5.82-10.11-5.12-22.14-4.11-33.34-2.42-5.56.84-11.87,1.62-16.24-1.92a5.108,5.108,0,0,1-.43-.38,35.011,35.011,0,1,1,70,1.74Z"
                transform="translate(199.857 1.492)"
                opacity="0.1"
              />
              <path
                id="Path_81"
                data-name="Path 81"
                d="M456.13,292s26,7,34,25,29.64,21.29,29.64,21.29"
                transform="translate(199.857 1.492)"
                opacity="0.05"
              />
              <rect
                id="Rectangle_5"
                data-name="Rectangle 5"
                width="60"
                height="19"
                transform="translate(693.987 689.492)"
                fill="#7d7d87"
              />
              <path
                id="Path_82"
                data-name="Path 82"
                d="M503.14,652.74c5.37-3,10.08-6,10.49-7.24,0,0,13.55,14.3,25.62,18.74A60.393,60.393,0,0,1,550.84,670c4.78,3,11.6,6.53,17.79,6.53,0,0,14,12-13,14s-90-5-90-5l4-25h0A60.77,60.77,0,0,0,503.14,652.74Z"
                transform="translate(199.857 1.492)"
                fill="#c4c7d8"
              />
            </g>
            <g id="hat" transform="translate(271.007 59.002)">
              <g transform="translate(271.007 59.002)">
                <path
                  id="Path_80"
                  data-name="Path 80"
                  d="M524.31,162.85c2.13,6.68,12.36,6.89,17.61,11.81,2.73,2.56,4,6.34,7,8.62,4.17,3.18,10.19,2.48,15.5,1.72,10.69-1.52,22.17-2.43,31.81,2.18,3.42,1.63,6.49,3.91,10.06,5.24s8,1.49,10.9-.87c2.32-1.89,3.17-5,5.27-7.08,1.63-1.64,3.9-2.56,5.76-4,4.08-3.08,5.84-8.1,7-12.9,2.4-9.88,3.09-20.53-1-29.9s-13.93-17-24.67-16.51c-3.29.15-6.5,1-9.78,1.36-8.55.87-17-2-25.58-2.49a14.57,14.57,0,0,0-7.33,1,13.379,13.379,0,0,0-4.41,3.95,42.13,42.13,0,0,0-7.86,17.48c-1,4.79-1.05,9.13-6.92,9.87C541,153.21,519.8,148.72,524.31,162.85Z"
                  transform="translate(-70.15 -55.51)"
                  fill="#472727"
                />
              </g>
            </g>
          </g>
        </svg>
      </div>
      <Chip
        label="#SVG image from - undraw.co"
        style={{ color: "rgb(50, 138, 118)", fontWeight: "600" }}
      />
    </div>
  );
};

export default ManOnCycle;
