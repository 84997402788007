import React from "react";
import Chip from "@material-ui/core/Chip";
import "./svgComponentsA.css";

const ToTheSpace = () => {
  return (
    <div className="containerSvg">
      <Chip size="small" label="SVG animation" clickable color="primary" />
      <Chip
        size="small"
        label="Figma"
        clickable
        color="primary"
        style={{ marginLeft: 5 }}
      />
      <Chip
        size="small"
        label="CSS"
        clickable
        color="primary"
        style={{ marginLeft: 5 }}
      />
      <div className="mainDivSvg" style={{ height: 500 }}>
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 754 751"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="launche">
            <g id="background">
              <path
                id="Vector"
                d="M754 377C754.084 467.413 721.642 554.84 662.599 623.312C603.556 691.785 521.852 736.734 432.41 749.95H330.3C330.14 750.09 324.48 750.36 324.48 750.36C323.53 750.23 322.57 750.09 321.62 749.95C314.35 748.88 307.147 747.603 300.01 746.12C272.065 740.335 244.877 731.36 218.98 719.37C218.71 719.25 218.45 719.12 218.18 719C203.749 712.302 189.763 704.683 176.31 696.19C175.97 695.98 175.64 695.77 175.31 695.56C174.74 695.2 174.18 694.85 173.62 694.48C173.44 694.37 173.26 694.26 173.08 694.14C161.053 686.393 149.477 677.966 138.41 668.9C137.87 668.47 137.33 668.02 136.79 667.58C134.62 665.78 132.47 663.96 130.34 662.12C130.32 662.11 130.31 662.09 130.29 662.08C119.406 652.647 109.066 642.604 99.3201 632C99.2901 631.96 99.2501 631.93 99.2201 631.89C56.4076 585.321 26.028 528.71 10.8901 467.29C10.7701 466.85 10.6701 466.42 10.5601 465.98C3.52595 436.843 -0.0189265 406.974 7.59918e-05 377C7.59918e-05 168.79 168.79 0 377 0C585.21 0 754 168.79 754 377Z"
                fill="#3F3D56"
              />
            </g>
            <g id="shootingStar2">
              <path
                id="Vector_2"
                opacity="0.8"
                d="M452.263 508.782L473.911 487.137L495.56 465.492L507.781 453.272C508.457 452.596 507.414 451.541 506.738 452.218L485.089 473.863L463.44 495.508L451.219 507.728C450.543 508.404 451.586 509.459 452.263 508.782V508.782Z"
                fill="url(#paint0_linear)"
              />
            </g>
            <g id="moon">
              <path
                id="Vector_3"
                d="M118.37 280C147.437 280 171 256.194 171 226.827C171 197.46 147.437 173.654 118.37 173.654C89.3038 173.654 65.7407 197.46 65.7407 226.827C65.7407 256.194 89.3038 280 118.37 280Z"
                fill="#E6E6E6"
              />
              <path
                id="Vector_4"
                d="M113.194 196.318C116.529 196.318 119.233 193.586 119.233 190.216C119.233 186.846 116.529 184.114 113.194 184.114C109.858 184.114 107.154 186.846 107.154 190.216C107.154 193.586 109.858 196.318 113.194 196.318Z"
                fill="#CBCBCB"
              />
              <path
                id="Vector_5"
                d="M123.547 257.336C126.883 257.336 129.587 254.604 129.587 251.234C129.587 247.864 126.883 245.133 123.547 245.133C120.212 245.133 117.508 247.864 117.508 251.234C117.508 254.604 120.212 257.336 123.547 257.336Z"
                fill="#CBCBCB"
              />
              <path
                id="Vector_6"
                d="M146.842 221.597C149.225 221.597 151.156 219.646 151.156 217.238C151.156 214.831 149.225 212.88 146.842 212.88C144.46 212.88 142.528 214.831 142.528 217.238C142.528 219.646 144.46 221.597 146.842 221.597Z"
                fill="#CBCBCB"
              />
              <path
                id="Vector_7"
                d="M97.6636 245.133C105.288 245.133 111.468 238.888 111.468 231.185C111.468 223.483 105.288 217.238 97.6636 217.238C90.0396 217.238 83.8591 223.483 83.8591 231.185C83.8591 238.888 90.0396 245.133 97.6636 245.133Z"
                fill="#CBCBCB"
              />
            </g>
            <g id="starSet1">
              <path
                id="Vector_8"
                d="M230.37 332.417C232.345 332.417 233.946 330.816 233.946 328.841C233.946 326.866 232.345 325.264 230.37 325.264C228.395 325.264 226.793 326.866 226.793 328.841C226.793 330.816 228.395 332.417 230.37 332.417Z"
                fill="#6C63FF"
              />
              <path
                id="Vector_9"
                d="M261.367 380.721L259.582 382.494L257.809 380.709L256.619 381.891L258.392 383.676L256.607 385.449L257.789 386.639L259.574 384.866L261.347 386.651L262.537 385.469L260.764 383.684L262.549 381.911L261.367 380.721Z"
                fill="#E6E6E6"
              />
              <path
                id="Vector_10"
                d="M275.673 277.003L273.888 278.776L272.115 276.991L270.925 278.173L272.698 279.958L270.913 281.731L272.095 282.921L273.88 281.148L275.653 282.933L276.843 281.751L275.07 279.966L276.855 278.193L275.673 277.003Z"
                fill="#E6E6E6"
              />
              <path
                id="Vector_11"
                d="M320.634 80.1838C316.772 82.757 312.819 76.6326 316.756 74.1736C320.617 71.6006 324.57 77.725 320.634 80.1838Z"
                fill="#E6E6E6"
              />
              <path
                id="Vector_12"
                d="M96.912 346.356L94.455 345.817L94.993 343.359L93.355 343L92.816 345.458L90.359 344.92L90 346.558L92.457 347.097L91.919 349.554L93.557 349.913L94.096 347.455L96.553 347.994L96.912 346.356Z"
                fill="#E6E6E6"
              />
              <path
                id="Vector_13"
                d="M472.912 51.356L470.455 50.817L470.993 48.359L469.355 48L468.816 50.458L466.359 49.92L466 51.558L468.457 52.097L467.919 54.554L469.557 54.913L470.096 52.455L472.553 52.994L472.912 51.356Z"
                fill="#E6E6E6"
              />
              <path
                id="Vector_14"
                d="M664.912 210.356L662.455 209.817L662.993 207.359L661.355 207L660.816 209.458L658.359 208.92L658 210.558L660.457 211.097L659.919 213.554L661.557 213.913L662.096 211.455L664.553 211.994L664.912 210.356Z"
                fill="#E6E6E6"
              />
              <path
                id="Vector_15"
                d="M200.815 90.134L198.358 89.595L198.896 87.138L197.258 86.779L196.719 89.236L194.261 88.698L193.902 90.336L196.36 90.875L195.822 93.333L197.46 93.691L197.999 91.234L200.456 91.772L200.815 90.134Z"
                fill="#E6E6E6"
              />
              <path
                id="Vector_16"
                d="M433.695 171.755C435.67 171.755 437.271 170.154 437.271 168.179C437.271 166.204 435.67 164.602 433.695 164.602C431.719 164.602 430.118 166.204 430.118 168.179C430.118 170.154 431.719 171.755 433.695 171.755Z"
                fill="#6C63FF"
              />
              <path
                id="Vector_17"
                d="M406.912 222.356L404.455 221.817L404.993 219.359L403.355 219L402.816 221.458L400.359 220.92L400 222.558L402.457 223.097L401.919 225.554L403.557 225.913L404.096 223.455L406.553 223.994L406.912 222.356Z"
                fill="#E6E6E6"
              />
              <path
                id="Vector_18"
                d="M501.815 178.134L499.358 177.595L499.896 175.138L498.258 174.779L497.719 177.236L495.261 176.698L494.902 178.336L497.36 178.875L496.822 181.333L498.46 181.691L498.999 179.234L501.456 179.772L501.815 178.134Z"
                fill="#E6E6E6"
              />
            </g>
            <g id="starSet2">
              <path
                id="Vector_19"
                d="M267.712 481.848C269.551 481.848 271.042 480.357 271.042 478.517C271.042 476.678 269.551 475.187 267.712 475.187C265.872 475.187 264.381 476.678 264.381 478.517C264.381 480.357 265.872 481.848 267.712 481.848Z"
                fill="#6C63FF"
              />
              <path
                id="Vector_20"
                d="M296.576 526.828L294.914 528.479L293.263 526.817L292.155 527.918L293.806 529.58L292.144 531.231L293.244 532.339L294.906 530.688L296.557 532.35L297.666 531.249L296.015 529.587L297.677 527.936L296.576 526.828Z"
                fill="#E6E6E6"
              />
              <path
                id="Vector_21"
                d="M309.898 430.246L308.236 431.897L306.585 430.235L305.476 431.336L307.127 432.998L305.465 434.649L306.566 435.757L308.228 434.106L309.879 435.768L310.987 434.668L309.336 433.006L310.998 431.355L309.898 430.246Z"
                fill="#E6E6E6"
              />
              <path
                id="Vector_22"
                d="M351.765 246.969C348.169 249.366 344.488 243.662 348.154 241.373C351.75 238.977 355.43 244.68 351.765 246.969Z"
                fill="#E6E6E6"
              />
              <path
                id="Vector_23"
                d="M143.436 494.828L141.148 494.326L141.649 492.037L140.124 491.702L139.622 493.991L137.334 493.49L137 495.016L139.288 495.518L138.787 497.805L140.312 498.14L140.814 495.851L143.102 496.353L143.436 494.828Z"
                fill="#E6E6E6"
              />
              <path
                id="Vector_24"
                d="M493.566 220.125L491.278 219.623L491.779 217.334L490.253 217L489.751 219.289L487.464 218.788L487.129 220.313L489.417 220.815L488.916 223.103L490.441 223.437L490.943 221.148L493.231 221.65L493.566 220.125Z"
                fill="#E6E6E6"
              />
              <path
                id="Vector_25"
                d="M672.355 368.185L670.067 367.683L670.568 365.394L669.043 365.06L668.541 367.349L666.253 366.848L665.919 368.373L668.206 368.875L667.706 371.163L669.231 371.497L669.733 369.208L672.021 369.71L672.355 368.185Z"
                fill="#E6E6E6"
              />
              <path
                id="Vector_26"
                d="M240.19 256.235L237.902 255.733L238.403 253.445L236.878 253.111L236.376 255.399L234.087 254.898L233.753 256.423L236.042 256.925L235.541 259.214L237.066 259.547L237.568 257.259L239.856 257.76L240.19 256.235Z"
                fill="#E6E6E6"
              />
              <path
                id="Vector_27"
                d="M457.047 332.24C458.886 332.24 460.377 330.749 460.377 328.91C460.377 327.071 458.886 325.579 457.047 325.579C455.207 325.579 453.716 327.071 453.716 328.91C453.716 330.749 455.207 332.24 457.047 332.24Z"
                fill="#6C63FF"
              />
              <path
                id="Vector_28"
                d="M432.107 379.359L429.819 378.857L430.32 376.569L428.795 376.234L428.293 378.523L426.005 378.022L425.67 379.547L427.958 380.049L427.457 382.337L428.983 382.672L429.485 380.383L431.773 380.885L432.107 379.359Z"
                fill="#E6E6E6"
              />
              <path
                id="Vector_29"
                d="M520.48 338.18L518.192 337.678L518.693 335.39L517.168 335.056L516.666 337.344L514.377 336.843L514.043 338.368L516.331 338.87L515.83 341.159L517.356 341.492L517.858 339.204L520.146 339.705L520.48 338.18Z"
                fill="#E6E6E6"
              />
            </g>
            <g id="shootingStar1">
              <path
                id="Vector_30"
                opacity="0.8"
                d="M354.418 186.729L378.725 159.767L403.032 132.805L416.754 117.585C417.513 116.743 416.342 115.429 415.582 116.271L391.275 143.233L366.968 170.195L353.246 185.415C352.487 186.257 353.658 187.571 354.418 186.729V186.729Z"
                fill="url(#paint1_linear)"
              />
            </g>
            <g id="rocket">
              <path
                id="Vector_31"
                d="M405.91 331.41H371.81V744.95H405.91V331.41Z"
                fill="#2F2E41"
              />
              <path
                id="Vector_32"
                d="M394.95 483.23L394.88 495.61L394.05 645.87L393.95 663.78L393.73 704.14L393.51 744.95H391.31L391.53 704.14L391.74 666.44L391.85 645.5L392.68 495.64L392.75 483.22L394.95 483.23Z"
                fill="#6C63FF"
              />
              <path
                id="Vector_33"
                d="M412.031 336.91H365.827C365.156 329.672 366.825 322.382 371.121 315.034L372.427 306.107H404.33L405.835 314.921C410.305 320.877 412.037 328.397 412.031 336.91Z"
                fill="#2F2E41"
              />
              <path
                id="Vector_34"
                d="M405.318 307.207H372.396C372.064 307.207 371.735 307.132 371.436 306.987C371.137 306.842 370.874 306.63 370.668 306.369C370.463 306.108 370.318 305.804 370.247 305.479C370.176 305.154 370.179 304.817 370.257 304.494L375.801 281.392C375.917 280.911 376.19 280.483 376.579 280.177C376.967 279.871 377.446 279.705 377.941 279.705H399.774C400.268 279.705 400.748 279.871 401.136 280.177C401.524 280.483 401.798 280.911 401.913 281.392L407.458 304.494C407.535 304.817 407.538 305.154 407.467 305.479C407.396 305.804 407.252 306.108 407.046 306.369C406.84 306.63 406.578 306.842 406.278 306.987C405.979 307.132 405.651 307.207 405.318 307.207V307.207Z"
                fill="#2F2E41"
              />
              <path
                id="Vector_35"
                d="M388.86 268.15C385.796 268.154 382.859 269.373 380.693 271.54C378.528 273.708 377.311 276.646 377.31 279.71V291.26H400.41V279.71C400.409 276.646 399.192 273.708 397.027 271.54C394.861 269.373 391.924 268.154 388.86 268.15V268.15Z"
                fill="#2F2E41"
              />
              <path
                id="Vector_36"
                d="M376.051 617.109L297.765 718.714L308.592 721.213L369.388 642.927H376.051V617.109Z"
                fill="#2F2E41"
              />
              <path
                id="Vector_37"
                d="M404.831 617.109L483.117 718.714L472.29 721.213L411.494 642.927H404.831V617.109Z"
                fill="#2F2E41"
              />
              <path
                id="Vector_38"
                d="M376.494 641.969H370.494V743.992H376.494V641.969Z"
                fill="#E6E6E6"
              />
              <path
                id="Vector_39"
                d="M390.54 267.6C388.089 268.263 385.924 269.714 384.379 271.729C382.834 273.744 381.995 276.211 381.99 278.75V278.85C381.623 278.96 381.291 279.163 381.026 279.44C380.761 279.717 380.573 280.058 380.48 280.43L374.94 303.54C374.865 303.852 374.86 304.176 374.925 304.49C374.99 304.804 375.123 305.099 375.315 305.356C375.507 305.613 375.752 305.824 376.035 305.975C376.318 306.126 376.63 306.213 376.95 306.23L376.91 306.5L376.76 307.5L374.5 314.5C370.21 321.84 369.84 328.71 370.51 335.95H376.49V616.46L298.45 717.76L304.19 719.08L303.28 720.26L292.45 717.76L370.49 616.46V335.95L365.5 335.5C364.83 328.26 366.21 321.84 370.5 314.5L370.76 307.5L370.91 306.5L370.95 306.23C370.63 306.213 370.318 306.126 370.035 305.975C369.752 305.824 369.507 305.613 369.315 305.356C369.123 305.099 368.99 304.804 368.925 304.49C368.86 304.176 368.865 303.852 368.94 303.54L374.48 280.43C374.573 280.058 374.761 279.717 375.026 279.44C375.291 279.163 375.623 278.96 375.99 278.85V278.75C375.991 275.686 377.208 272.748 379.373 270.58C381.539 268.413 384.476 267.194 387.54 267.19C388.554 267.193 389.563 267.331 390.54 267.6V267.6Z"
                fill="#E6E6E6"
              />
              <path
                id="Vector_40"
                d="M416.177 641.969H410.594V642.506L470.974 720.255L476.062 719.081L416.177 641.969Z"
                fill="#E6E6E6"
              />
              <path
                id="Vector_41"
                d="M370.49 335.95H364.51C363.84 328.71 365.51 321.42 369.8 314.08L370.76 307.5L370.91 306.5H376.91L376.76 307.5L375.8 314.08C371.51 321.42 369.84 328.71 370.51 335.95H376.49"
                fill="#6C63FF"
              />
              <path
                id="Vector_42"
                d="M376.49 422.5H370.49V495.5H376.49V422.5Z"
                fill="#6C63FF"
              />
            </g>
          </g>
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="451"
              y1="480.5"
              x2="508"
              y2="480.5"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="white" />
              <stop offset="1" stop-color="white" stop-opacity="0.3" />
            </linearGradient>
            <linearGradient
              id="paint1_linear"
              x1="37404.6"
              y1="17369.2"
              x2="42960.4"
              y2="17369.2"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="white" />
              <stop offset="1" stop-color="white" stop-opacity="0.3" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <Chip
        label="#SVG image from - undraw.co"
        style={{ color: "rgb(50, 138, 118)", fontWeight: "600" }}
      />
    </div>
  );
};

export default ToTheSpace;
